import React, { useState } from 'react';
import classes from './AnticipatedIncomeComp.module.scss'
import 'react-rangeslider/lib/index.css'
import Slider from "react-rangeslider";
import { connect } from "react-redux";
import { setAnticipatedIncome } from "../../../../store/actions/aboutYou";

const AnticipatedIncomeComp = ( props ) => {

    const [ value, setValue ] = useState( props.anticipatedIncome ? props.anticipatedIncome : 2400);
    const horizontalLabels = {
        10000: `$10, 000`,
        0: `$0`
    };

    const onChangeRangeHandler = (value) => {
        props.setAnticipatedIncome(value)
        setValue(value)
    };

    const format = value => '$' + value

    return (
        <React.Fragment>
            <Slider
                className='largeRangeSlider'
                min={0}
                max={10000}
                value={value}
                tooltip={false}
                handleLabel={value.toString()}
                format={format}
                labels={horizontalLabels}
                onChange={value => onChangeRangeHandler(value)}
            />
            <span className={classes.Value}>{'$' + value}</span>
            <div className={classes.Description}>
                Drag slider or edit to change monthly income goal
            </div>
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        anticipatedIncome: state.aboutYou.anticipatedIncome,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setAnticipatedIncome: val => dispatch(setAnticipatedIncome(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnticipatedIncomeComp);
import React from 'react';
import classes from "./CottageEquity.module.scss";

const RentalEquity = () => {
    return (
        <div className={classes.cottageEquity}>
            <p>To calculate the equity in your rental property(ies), start with your property's value and subtract the amount owed on any mortgages.</p>
            <p>
                <strong>example:</strong>
                Property value
                <strong>$500,000</strong>
                <strong>-</strong>
                Mortgage value
                <strong>$300,000</strong>
                <strong>=</strong>
                Property equity
                <strong>$200,000</strong>
            </p>
        </div>
    )
};;

export default RentalEquity;
import React from 'react';

const AnticipatedIncome = () => (
    <React.Fragment>
        <p>The average Canadian retiree spends $2,400 a month ($28,800 per year)</p>
        <p><strong>Average Monthly Expenses for Canadian Retirees</strong></p>
        <ul style={{listStyle: 'none' }}>
            <li>$668 is housing</li>
            <li>$581 living expenses – clothing, bills and transportation</li>
            <li>$282 on travel</li>
            <li>$151 medical expenses</li>
        </ul>
        <p style={{fontSize: 12}}>source: Moneysense.ca, June 25, 2018 <br/> https://www.moneysense.ca/save/retirement/canadian-retirees-spending-an-average-2400-a-month/</p>
    </React.Fragment>
);

export default AnticipatedIncome;
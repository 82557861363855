import React from 'react';

const ShortTermLosses = () => {
    return (
        <React.Fragment>
            <p>“Short term” typically means between 1 and 3 years. If markets go down, think about how comfortable you would be staying invested for this time period.</p>
        </React.Fragment>
    )
};

export default ShortTermLosses;
import React, {Component} from "react";
import classes from './InnerLayout.module.scss'
import './InnerLayout.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import MoreInfo from "../../components/MoreInfo/MoreInfo";
export default class InnerLayout extends Component{

    render() {
        let icons;
        let iconsTitle;

        if (this.props.pathVal === '/anticipated-income' || this.props.pathVal === '/inflation'){
            icons = <React.Fragment>
                    <FontAwesomeIcon
                        icon={faUserCircle}
                        style={{fontSize: 60, color:'#3967b1'}}
                    />
                    <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{fontSize: 60, color: 'rgba(0,0,0, .5)'}}
                    />
                    </React.Fragment>
            iconsTitle = 'Family'
        }else {
            icons = <FontAwesomeIcon
                        icon={faUserCircle}
                        style={{fontSize: 60, color:'#3967b1'}}
                    />
            iconsTitle = 'You'
        }
        return(
            <React.Fragment>
                <h2>
                    {this.props.sectionTitle}
                </h2>

                <div className={classes.InnerLayout}>
                    <div className={classes.You}>
                        {/*<div>
                            {icons}
                        </div>
                        {iconsTitle}*/}
                    </div>
                    <div className={classes.MainContent}>
                        {this.props.children}
                    </div>
                    <div className={classes.MoreInfo}>
                        <Popup modal trigger={<div>
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                style={{fontSize: 60,cursor: 'pointer', color:'#3967b1'}}
                            />
                        </div>}>
                        {close => <MoreInfo type={this.props.modalContent} close={close}/>}
                        </Popup>
                        <span className={classes.MoreInfoTitle}>More info</span>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}
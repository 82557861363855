import React from 'react';
import classes from './CottageEquity.module.scss'

const CottageEquity = () => {
    return (
        <div className={classes.cottageEquity}>
            <p>To calculate the equity in your cottage, start with your cottage's value and subtract the amount owed on any mortgages.</p>
            <p>
                <strong>example:</strong>
                Cottage value
                <strong>$500,000</strong>
                <strong>-</strong>
                Mortgage value
                <strong>$300,000</strong>
                <strong>=</strong>
                Cottage equity
                <strong>$200,000</strong>
            </p>
        </div>
    )
};

export default CottageEquity;
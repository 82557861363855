import React from 'react';

const SavingsOrInvestmentSelector = ( props ) => (
    <React.Fragment>
        <p><strong>Registered Retirement Saving Plan (RRSP)</strong>- A registered account within which interest earned, dividends, and capital gains grow tax-free until withdrawal(typically, but not always at retirement), when it is taxed as income. Contribution limits are defined by the Canada Revenue Agency and subject to change.</p>
        <p><strong>Locked-In</strong>- A registered account from which investments cannot be withdrawn until either retirement or at an age specified in the applicable pension legislation.</p>
        <p><strong>Non-Registered</strong>- Investment, banking, or other accounts within which income and capital gains are taxable.</p>
        <p><strong>Workplace Savings </strong>- An employer sponsored benefit plan, usually to help save for retirement. Types of plan can include TFSA, RRSP, non-registered, and others.</p>
        <p><strong>Tax Free Savings Account (TFSA) </strong>- A registered account within which no taxes are paid on interest earned, dividends or capital gains,and from which funds can be withdrawn tax free at any time. Contribution limits are defined by the Canada Revenue Agency and subject to change.</p>
    </React.Fragment>
);

export default SavingsOrInvestmentSelector;
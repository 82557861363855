import React from 'react';

const Inflation = ( props ) => {
    return (
        <React.Fragment>
            <p>The higher the rate of inflation, the more it will cost to purchase goods and services in the future. If the rate of inflation is high, you'll need to get a better return on your investments to keep up.</p>
            <p style={{fontSize: 12}}>See <a href="https://www.bankofcanada.ca/rates/related/inflation-calculator" target='_blank' rel="noopener noreferrer">www.bankofcanada.ca/rates/related/inflation-calculator</a> for current inflation rate.</p>
            <p><strong>Why Increase/Decrease Inflation Rate?</strong></p>
            <p>If the rate of inflation is higher than the absolute rate of return on your investments, your ability to purchase goods and services with those investments will decline over time.</p>
            <p style={{fontSize: 12}}>source: <a href="https://www.inflation.eu/inflation-rates/canada/historic-inflation/cpi-inflation-canada.aspx" target='_blank' rel="noopener noreferrer">www.inflation.eu/inflation-rates/canada/historic-inflation/cpi-inflation-canada.aspx</a></p>
        </React.Fragment>
    )
};

export default Inflation;
import React from 'react';

const OtherSourcesSelector = () => (
    <React.Fragment>
        <p><strong>Canadian Pension Plan (CPP)/ Quebec Pension Plan (QPP)</strong> - The Canada Pension Plan (CPP) and Québec Pension Plan (QPP) provide monthly payments to people who contributed to the plans during their working years.</p>
        <p>The amount you'll get every month depends on a number of variables, including the length of time and the amount that you contributed to the plan.</p>
        <p><strong>OAS</strong> - The Old Age Security (OAS) pension is a monthly benefit for Canadians over the age of 65 years old. You can get Old Age Security pension benefits even if you're still working or have never worked.</p>
        <p><strong>Employer Pension</strong> - An employer pension plan is a registered plan that provides you with a source of income during your retirement. Under these plans, you and your employer (or just your employer) regularly contribute money to the plan. When you retire, you'll receive an income from the plan.</p>
        <p><strong>Other</strong> - Other sources of income in retirement might include income from rental properties, part-time work, alimony, or disability payments.</p>
    </React.Fragment>
);

export default OtherSourcesSelector;
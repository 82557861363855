import React from 'react';
import RetireAge from "./RetireAge";
import YearsIncome from "./YearsIncome";
import RentOwn from "./RentOwn";
import AnticipatedIncome from "./AnticipatedIncome";
import Inflation from "./Inflation";
import ShortTermLosses from "./ShortTermLosses";
import LongTermReturns from "./LongTermReturns";
import RecoveryPeriod from "./RecoveryPeriod";
import GuaranteedFee from "./GuaranteedFee";
import RateOfReturn from "./RateOfReturn";
import { ReactComponent as Close } from "../../assets/images/CloseBtn.svg";
import SavingsOrInvestmentSelector from "./SavingsOrInvestmentSelector";
import WorkplaceSavingsLegislationType from "./WorkplaceSavingsLegislationType";
import LockedInLegislationType from "./LockedInLegislationType";
import CottageEquity from "./Properties/CottageEquity";
import RentalEquity from "./Properties/RentalEquity";
import InheritanceAmount from "./InheritanceAmount";
import OtherSourcesSelector from "./OtherSourcesSelector";
import CppInfo from "./CppInfo";
import QppInfo from "./QppInfo";
import OasPensionInfo from "./OasPensionInfo";

const MoreInfo = ( props ) => {

    const modalContent=() => {
        switch (props.type ) {
            case 'retireAge':
                return <RetireAge/>
            case 'yearsIncome':
                return <YearsIncome/>
            case 'rentOwn':
                return <RentOwn/>
            case 'anticipatedIncome':
                return <AnticipatedIncome/>
            case 'inflation':
                return <Inflation/>
            case 'shortTermLosses':
                return <ShortTermLosses/>
            case 'longTermReturns':
                return <LongTermReturns/>
            case 'recoveryPeriod':
                return <RecoveryPeriod/>
            case 'guaranteedFee':
                return <GuaranteedFee/>
            case 'guaranteedPercentage':
                return <GuaranteedFee/>
            case 'rateOfReturn':
                return <RateOfReturn/>
            case 'savingsOrInvestmentSelector':
                return <SavingsOrInvestmentSelector/>
            case 'workplaceSavingsLegislationType':
                return <WorkplaceSavingsLegislationType/>
            case 'lockedInLegislationType':
                return <LockedInLegislationType/>
            case 'cottageEquity':
                return <CottageEquity/>
            case 'rentalEquity':
                return <RentalEquity/>
            case 'inheritanceAmount':
                return <InheritanceAmount/>
            case 'otherSourcesSelector':
                return <OtherSourcesSelector/>
            case 'cpp':
                return <CppInfo/>
            case 'qpp':
                return <QppInfo/>
            case 'oasPension':
                return <OasPensionInfo/>
            default:
                return ''
        }
    }

    return (
        <React.Fragment>
            {modalContent()}
            <div className="closeBtn">
                <Close onClick={props.close}/>
            </div>
        </React.Fragment>
    )
};

export default MoreInfo;
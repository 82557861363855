import React from 'react';
import { Helmet } from "react-helmet";
import InnerLayout from "../../../hoc/InnerLayout/InnerLayout";
import Navigation from "../../../components/Navigation/Navigation";
import Layout from "../../../hoc/Layout/Layout";
import { withRouter } from "react-router-dom";
import AnticipatedIncomeComp from "../../../components/ContentRow/Step1/AnticipatedIncomeComp/AnticipatedIncomeComp";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const AnticipatedIncome = ( props ) => {

    const titles = {
        layoutTitle: 'TELL US ABOUT YOURSELF',
        sectionTitle: 'How much combined monthly income do you anticipate you’ll need in retirement?',
    }

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep1={(5/7)*100}
            active={'step1'}
        >
            <Helmet>
                <title>{titles.sectionTitle}</title>
            </Helmet>

            <InnerLayout
                sectionTitle={titles.sectionTitle}
                modalContent='anticipatedIncome'
                pathVal={props.match.path}
            >

                <AnticipatedIncomeComp/>

            </InnerLayout>

             <Navigation
                 isCompleted={true}
                 next={retirementCalcLinks.inflation}
             />

        </Layout>
    )
};

export default withRouter(AnticipatedIncome);